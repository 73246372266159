import React from "react";
import classNames from "classnames";
import { Container, Text } from "@atoms";
import { NextArrow } from "@molecules";

const Hero = ({ heading, subheading, description, largerHeadingSpace }) => {
  return (
    <section className="py-24 md:py-28">
      <Container frame>
        <div
          className={classNames("flex max-w-2xl flex-col", {
            "gap-8 sm:gap-10 lg:gap-14": !largerHeadingSpace,
            "sm:gap-30 gap-16 lg:gap-40": largerHeadingSpace,
          })}
        >
          <Text variant="h1" className="text-white">
            {heading}
          </Text>
          {subheading && (
            <Text className="max-w-md text-2xl font-bold uppercase text-white">
              {subheading}
            </Text>
          )}
          <div className="max-w-md">
            <Text variant="h6" className="text-white">
              {description}
            </Text>
            <NextArrow className="mt-8" />
          </div>
        </div>
      </Container>
    </section>
  );
};

Hero.defaultProps = {};

export default Hero;
